<template>

<div class="sm-container">
  <div class="result">
    <!--  成功  -->
    <div v-if="success" class="result-c">
      <img src="@/assets/img/success.png">
      <h3>Confirmed Successful</h3>
<!--      <div class="result-btn">-->
<!--        <router-link to="/">-->
<!--          Back To Home-->
<!--        </router-link>-->
<!--      </div>-->
    </div>
    <!--  失败  -->
    <div v-else  class="result-c">
      <img src="@/assets/img/fail.png">
      <h3>Sorry, Confirmed Failed</h3>
      <p>{{ errorMessage }}</p>
      <div class="result-btn">
        <router-link to="/cart">
          Back To Shopping Chart
        </router-link>
      </div>
    </div>

  </div>
</div>
</template>

<script>

export default {
  name: "Result",
  data() {
    return {
      success: false,
      errorMessage: ''
    };
  },
  created() {
    this.success = this.$route.query.success === 'true';
    this.errorMessage = this.$route.query.errorMessage || '';
  }
}
</script>

<style scoped>
.result{
  min-height: 800px;
}
.result h1{
  padding: 30px 0;
}
.result-c{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding-top:15%;
}
.result-c img{
  width: 200px;
}
.result-c h3{
  color: var(--background);
  font-size: 26px;
}
.result-c p{
   color: #FF2727;
   padding-top: 10px;
}
.result-c p.success-tips{
  color: var(--background);
  padding-top: 10px;
}
.result-btn a{
  background: var(--background);
  padding: 20px 40px;
  display:inline-block;
  margin-top: 30px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
@media only screen and (min-width:0px) and (max-width:767px){
  .result-c h3{
    font-size: 20px;
  }
}
</style>